import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Header(props) {
    const [scrolled, setScrolled] = useState(false);
    function handleScroll() {
        const position = window.pageYOffset;
        if (position > 10) {
            setScrolled(true);
        }
        else {
            setScrolled(false);
        }
    };

    const navigationHistory = useNavigate();
    const location = useLocation();

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    }, [navigationHistory]);

    if (location.pathname.length !== 1 && location.pathname !== '/') {
        return <Navbar scrolled={true} />
    }

    return (
        <Navbar scrolled={scrolled} />
    )
}