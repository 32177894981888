import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import AOS from 'aos';
import RoutingServices from './services/RoutingServices';
import history from './services/historyService';
import Header from './common/navbar/Header';
import './App.css';

import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';

function App() {

  useEffect(() => {
    AOS.init();
  });

  return (
    <React.Fragment>
      <Router history={history}>
        <Header />
        <RoutingServices />
      </Router>
    </React.Fragment>
  );
}

export default App;
