/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import CompanyLogoWhite from '../../../assets/images/company_logo/uno_white.webp'
import CompanyLogo from '../../../assets/images/company_logo/uno.webp'
import config from '../../../constants/routeConfig';
import { HiMenuAlt4 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';

export default function Navbar(props) {
    const { scrolled } = props;
    const [activePage, setActivePage] = useState('/');
    const navigationHistory = useNavigate();
    const location = useLocation();

    function openDrawer() {
        $('.navbar-drawer-overlay').css('display', 'block');
        $('.navbar-drawer').css('animation', 'slideIn 200ms linear forwards');
    }

    function closeDrawer() {
        $('.navbar-drawer-overlay').css('display', 'none');
        $('.navbar-drawer').css('animation', 'slideOut 200ms linear forwards');
    }

    useEffect(() => {
        setActivePage(location.pathname);
    }, [navigationHistory]);

    return (
        <nav id="custom-navbar" className={`custom-navbar w-100 py-2 py-md-2 py-lg-0 ${scrolled && 'scrolled'}`}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                            <div className="font-large font-weight-semi-bold text-uppercase text-decoration-none">
                                <Link to={config.home.path}>
                                    <div className={`company-logo-200 ${scrolled && 'scrolled'}`}></div>
                                </Link>
                            </div>
                        </div>
                        <div className="d-none d-md-none d-lg-flex d-xl-flex flex-column justify-content-start align-items-end mx-5">
                            <div className="d-flex justify-content-end align-items-center pt-3">
                                <div className="px-3 border-right-amber">
                                    <Link to={config.safetyAndValues.path} className={`${scrolled && 'scrolled'} ${activePage === '/safety-values' ? 'active' : ''} custom-navbar-links text-decoration-none ml-1 font-weight-semi-bold`}>
                                        Safety and Values
                                    </Link>
                                </div>
                                <div className="pl-3 ml-1">
                                    <Link to={config.careers.path} className={`${scrolled && 'scrolled'} ${activePage === '/careers' ? 'active' : ''} custom-navbar-links text-decoration-none font-weight-semi-bold`}>
                                        Careers
                                    </Link>
                                </div>
                                {/* <div className="ml-1">
                                    <div className="ml-1">
                                        <Link to={config.gallery.path} className={`${scrolled && 'scrolled'} ${activePage === '/gallery' ? 'active' : ''} custom-navbar-links text-decoration-none font-weight-semi-bold`}>
                                            Gallery
                                        </Link>
                                    </div>
                                </div> */}
                            </div>
                            <div>
                                <ul className="navbar-nav d-flex flex-row justify-content-center align-items-center mr-4">
                                    <li className="mr-4 pr-2">
                                        <Link className={`custom-navbar-links ${activePage === '/' ? 'active' : ''} text-decoration-none font-weight-semi-bold mx-3 ${scrolled && 'scrolled'}`} to={config.home.path}>
                                            HOME
                                        </Link>
                                    </li>
                                    {/* <li className="px-4">
                                        <div className="position-relative px-md-0 mx-md-0 mx-lg-2 px-lg-2">
                                            <a className={`${activePage === '/products' ? 'active' : ''} ${scrolled && 'scrolled'} custom-navbar-links menu-tracksheet text-decoration-none d-flex justify-content-start align-items-center`} href="">
                                                <div className="tracksheet-text text-uppercase font-weight-semi-bold">
                                                    Products
                                                </div>
                                            </a>
                                        </div>
                                    </li> */}
                                    <li>
                                        <div className="position-relative">
                                            <Link to={config.network.path} className={`${activePage === '/our-network' ? 'active' : ''} ${scrolled && 'scrolled'} custom-navbar-links text-uppercase menu-services text-decoration-none d-flex justify-content-start align-items-center`}>
                                                <div className="services-text font-weight-semi-bold">
                                                    Our network
                                                </div>
                                            </Link>
                                        </div>
                                    </li>
                                    <li className="mx-4 ">
                                        <div className="mx-2">
                                            <Link to={config.aboutUs.path} className={`${activePage === '/about-us' ? 'active' : ''} custom-navbar-links text-decoration-none font-weight-semi-bold mx-3 ${scrolled && 'scrolled'}`}>
                                                ABOUT US
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to={config.contactUs.path} className={`${activePage === '/contact-us' ? 'active' : ''} ${scrolled && 'scrolled'} custom-navbar-links text-decoration-none text-uppercase font-weight-semi-bold`}>
                                            CONTACT US
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex d-md-flex d-lg-none d-xl-none justify-content-end align-items-center">
                        <button onClick={openDrawer} className="d-block d-md-block d-lg-none d-xl-none transparent-btn line-height-reducer navbar-menu-btn">
                            <HiMenuAlt4 color="#FFA602" size={30} />
                        </button>
                    </div>
                </div>
                <div className="navbar-drawer">
                    <div className="d-flex justify-content-between align-items-center mb-2 navbar-drawer-header  p-3">
                        <div className="font-medium font-weight-semi-bold text-uppercase text-white">
                            <Link to={config.home.path} onClick={closeDrawer}>
                                <div className={`company-logo-200 ${scrolled && 'scrolled'}`}></div>
                            </Link>
                        </div>
                        <div className="close-drawer">
                            <AiOutlineClose color="#fff" size={30} onClick={closeDrawer} />
                        </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start align-items-start p-3">
                        <div className="mb-3">
                            <Link to={config.home.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                HOME
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to={config.network.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                Our Network
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to={config.safetyAndValues.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                Safety and Values
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to={config.careers.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                Careers
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to={config.contactUs.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                Contact us
                            </Link>
                        </div>
                        <div className="mb-3">
                            <Link to={config.aboutUs.path} onClick={closeDrawer} className={`custom-navbar-menu-links text-decoration-none text-uppercase font-weight-semi-bold ${scrolled && 'scrolled'}`}>
                                About us
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="navbar-drawer-overlay" onClick={closeDrawer}></div>
            </div >
        </nav >
    );
}

