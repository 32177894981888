const config = {
    home: { path: '/', title: 'Home' },
    aboutUs: { path: '/about-us', title: 'About Us' },
    contactUs: { path: '/contact-us', title: 'Contact Us' },
    careers: { path: '/careers', title: 'Careers' },
    safetyAndValues: { path: '/safety-values', title: 'Safety and Values' },
    network: { path: '/our-network', title: 'Our Network' },
    gallery: { path: '/gallery', title: 'Our Gallery' },
    loader: { path: '/loader', title: 'loader' }
}

export default config;