import React from 'react';
import loadable from '@loadable/component';
import Loader from '../components/Loader';
import config from './routeConfig';

const Home = loadable(() => import('../pages/Home'), { fallback: <Loader /> });
const AboutUs = loadable(() => import('../pages/AboutUs'), { fallback: <Loader /> });
const ContactUs = loadable(() => import('../pages/ContactUs'), { fallback: <Loader /> });
const Careers = loadable(() => import('../pages/Careers'), { fallback: <Loader /> });
const SafetyValuesMission = loadable(() => import('../pages/SafetyValuesMission'), { fallback: <Loader /> });
const Network = loadable(() => import('../pages/Network'), { fallback: <Loader /> });

// const Home = loadable(() => import('../pages/Home'), { fallback: <div>1213</div> });
// const AboutUs = loadable(() => import('../pages/AboutUs'), { fallback: <div>1213</div> });
// const ContactUs = loadable(() => import('../pages/ContactUs'), { fallback: <div>1213</div> });
// const Careers = loadable(() => import('../pages/Careers'), { fallback: <div>1213</div> });
// const SafetyValuesMission = loadable(() => import('../pages/SafetyValuesMission'), { fallback: <div>1213</div> });
// const Loader = loadable(() => import('../components/Loader'), { fallback: <div>1213</div> });
// const Network = loadable(() => import('../pages/Network'), { fallback: <div>1213</div> });

const routes = [
    {
        path: config.home.path,
        title: config.home.title,
        component: Home,
    },
    {
        path: config.aboutUs.path,
        title: config.aboutUs.title,
        component: AboutUs,
    },
    {
        path: config.contactUs.path,
        title: config.contactUs.title,
        component: ContactUs,
    },
    {
        path: config.careers.path,
        title: config.careers.title,
        component: Careers,
    },
    {
        path: config.safetyAndValues.path,
        title: config.safetyAndValues.title,
        component: SafetyValuesMission,
    },
    {
        path: config.loader.path,
        title: config.loader.title,
        component: Loader,
    },
    {
        path: config.network.path,
        title: config.network.title,
        component: Network,
    },
]

export default routes;