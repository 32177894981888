import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../constants/routes';

function RoutingServices() {
    let appRoute = routes;
    return (
        <React.Fragment>
            <Routes>
                {appRoute.map(route => {
                    return (
                        <Route key={route.title} path={route.path} element={<route.component />} />
                    )
                })}
            </Routes>
        </React.Fragment>
    );
};


export default RoutingServices;