import React, { useEffect } from 'react';
import CompanyLogo from '../../assets/images/company_logo/uno.webp';

function Loader() {

    useEffect(() => {
        document.getElementById("custom-navbar").style = 'display: none';
        return () => {
            document.getElementById("custom-navbar").style = 'display: block';
        }
    }, []);

    return (
        <div className='w-100 h-100 bg-white background-white'>
            <div className='w-100 h-100 position-relative'>
                <div className='loader-icons'>
                    <div className='container-loader'>
                        <div className='container-image-container'>
                            <img src={CompanyLogo} className="company-logo-200" alt="" />
                        </div>
                        <div className="loader">
                            <span style={{ '--i': '0' }}></span>
                            <span style={{ '--i': '1' }}></span>
                            <span style={{ '--i': '2' }}></span>
                            <span style={{ '--i': '3' }}></span>
                            <span style={{ '--i': '4' }}></span>
                            <span style={{ '--i': '5' }}></span>
                            <span style={{ '--i': '6' }}></span>
                            <span style={{ '--i': '7' }}></span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Loader;